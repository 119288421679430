






import { Component, Mixins } from 'vue-property-decorator';

import CurrentModule from "@/components/Modules/components/CurrentModule.vue";
import TempCurrentModuleMixin from "@/includes/logic/Modules/mixins/TempCurrentModuleMixin";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import CenteredColumnLayout from 'piramis-base-components/src/components/CenteredColumnLayout.vue';

@Component({
  components: {
    CurrentModule,
    CenteredColumnLayout
  }
})
export default class NewReputation extends Mixins(TempCurrentModuleMixin) {
  created() {
    this.setCurrentModule(ModuleTypesEnum.ReputationMessageHandlerModule)
  }
}
